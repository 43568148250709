// local
// const BASE_URL = "http://192.168.21.19:5000/node-api/";
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

//demo
const BASE_URL = "https://pelster.admin.iossmlm.com/node-api/";
const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

//live
// const BASE_URL = "https://admin.timeless-hab.com/node-api/";
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const PAYPAL_CLIENT_ID = ""


export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID } 