import { useTranslation } from "react-i18next";
import backToOffice from "../../services/auth/backToOffice";

const BackToOffice = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let string = urlParams.get("string");
  let code = urlParams.get("db_prefix");
  const { i18n } = useTranslation();
  backToOffice.accessToken(string, code)
    .then((res) => {
      localStorage.setItem('access-token', res.data?.accessToken)
      localStorage.setItem('api-key', res.data?.apiKey)
      localStorage.setItem('user', JSON.stringify(res.data?.user))
      localStorage.setItem('defaultCurrency', JSON.stringify(res.data?.defaultCurrency))
      localStorage.setItem('defaultLanguage', JSON.stringify(res.data?.defaultLanguage))
      window.location.href = "/dashboard"
      console.log("res.data?.defaultLanguage?.code ================ ",res.data?.defaultLanguage?.code);
      i18n.changeLanguage(res.data?.defaultLanguage?.code);
    })
};

export default BackToOffice;
