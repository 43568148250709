import React, { useState } from "react";
import QuickBalance from "../../components/Dashboard/DashboardTiles";
import JoiningChart from "../../components/Dashboard/JoiningChart";
import TeamMembers from "../../components/Dashboard/TeamMembers";
import TeamMembersEarningSection from "../../components/Dashboard/TeamPerformance";
import EarningsExpenses from "../../components/Dashboard/Earnings";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RankComponent from "../../components/Dashboard/RankComponent";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { DatePicker } from "antd";
import { formatDateWithoutTime } from "../../utils/formateDate";
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [dateKey, setDateKey] = useState(Date.now());
  const user = JSON.parse(localStorage.getItem("user"));
  const joiningChartData = useSelector(
    (state) => state.dashboard?.dashboardOne
  );
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );

  // --------------------------------------------- API -------------------------------------------------
  const dashboard = ApiHook.CallDashboardTiles(date);
  const dashboardDetails = ApiHook.CallDashboardDetails();

  if (dashboard.isFetching) {
    // return null;
  }
  const handleDateRange = (dates) => {
    if (Array.isArray(dates) && dates.length === 2) {
      const [toDate, fromDate] = dates;
      setDate({
        startDate: toDate.format("YYYY-MM-DD"),
        endDate: fromDate ? fromDate.format("YYYY-MM-DD") : null
      });
    }
  };
  const resetDateRange = () => {
    setDate({ startDate: "", endDate: "" });
  }
  return (
    <>
      <div className="page_head_top">{t("dashboard")}</div>
      <div className="center_content_head">
        <span>
          {t("welcome")} {user?.fullName}{" "}
        </span>
        <span className="dashboard-filter">
          {date.startDate && date.endDate && <span>{`${formatDateWithoutTime(date.startDate)} to ${formatDateWithoutTime(date.endDate)}`} <i class="fa-solid fa-circle-xmark dashboard-filter-icon" onClick={resetDateRange}></i> </span>}
          <div className="filter-icon-div dashboard-filter-icon">
            {!date.startDate && !date.endDate && <OverlayTrigger
              trigger="click"
              key={"date"}
              placement={'left'}
              overlay={
                <Popover id={`filter`}>
                  <Popover.Body>
                    <RangePicker key={dateKey} onChange={(dates) => handleDateRange(dates)} />
                  </Popover.Body>
                </Popover>
              }
            >
              <i class="fa-regular fa-calendar dashboard-filter-icon"></i>
            </OverlayTrigger>}
          </div>
        </span>
      </div>
      <QuickBalance
        tiles={dashboard?.data}
        currency={userSelectedCurrency}
        conversionFactor={conversionFactor}
      />
      <div className="joining_Teammbr_section">
        <div className="row">
          <JoiningChart charts={joiningChartData} />
          <TeamMembers members={dashboardDetails?.data?.newMembers} />
        </div>
      </div>
      <div className="team_members_earning_section">
        <div className="row">
          <TeamMembersEarningSection
            topEarners={dashboardDetails?.data?.topEarners}
            currency={userSelectedCurrency}
            conversionFactor={conversionFactor}
          />
          {!!moduleStatus?.rank_status && (
            <RankComponent
              ranks={dashboardDetails?.data?.ranks}
              currentRank={dashboardDetails?.data?.currentRank}
            />
          )}
          <EarningsExpenses
            earnings={dashboardDetails?.data?.earnings}
            currency={userSelectedCurrency}
            conversionFactor={conversionFactor}
          />
        </div>
      </div>

    </>
  );
};

export default Dashboard;
