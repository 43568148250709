import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import BankDetailsTabNew from "./BankDetailsNew";

const PaymentDetailsTab = ({ payment, bank }) => {
  const buttonLoading = (
    <div>
      <i class="fa-solid fa-spinner fa-spin"></i> processing
    </div>
  );
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [paymentDetails, setPaymentDetails] = useState({
    paymentMethod: payment.id,
    value: payment.value,
    paypalAccount: payment?.paypalAccount ?? null,
    stripeAccount: payment?.stripeAccount ?? null
  });
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const paymentMutation = ApiHook.CallPaymentDetails();
  const createStripe = ApiHook.CallCreateStripeAccount();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setPaymentDetails((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };
  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    setPaymentDetails((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
      paypalAccount: payment.paypalAccount,
      stripeAccount: payment.stripeAccount
    }));
  };
  const createStripeHandler = async (type) => {
    const response = await createStripe.mutateAsync(type);
    if (response?.status) {
      if (type === "create") {
        onSubmit();
      }
      window.location.href = response?.data;
    } else {
      toast.error("some error occured");
    }
  };
  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
  };
  console.log("paymentDetails.value === ", paymentDetails.value);
  const onSubmit = () => {
    if (paymentDetails.paymentMethod == 6 && !paymentDetails.paypalAccount) {
      toast.error(t("updatePaypalAccountDetails"))
      return
    }
    paymentMutation.mutate(paymentDetails, {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ["profile"] });
        toast.success(res?.data);
        setIsEditModeEnabled(false);
      },
    });
  };

  return (
    <div id="fourthTab" className="tabcontent">
      <div className="editSec">
        <div className={`editSec ${isEditModeEnabled ? "disabled" : ""}`}>
          <span
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={toggleEditMode}
          >
            <i
              className="fa-solid fa-pen-to-square"
              style={{ color: "#32009c" }}
            ></i>
          </span>
        </div>
      </div>
      <h3>{t("paymentDetails")}</h3>
      <div className="tabcontent_form_section_bt">
        <div className="mb-3 row tabBlockClass">
          <label className="col-sm-3 col-form-label labelWidthClass">
            {t("payment_method")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <select
              name="paymentMethod" // Add a name to your select element
              className="form-select"
              disabled={!isEditModeEnabled}
              value={paymentDetails.paymentMethod || ""} // Set the value of the select based on state
              onChange={handleOptionChange}
            >
              {payment?.options.map((option, key) => (
                <option key={key} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {Number(paymentDetails.paymentMethod) === 5 && (
          <div className="mb-3 row tabBlockClass">
            <label className="col-sm-3 col-form-label labelWidthClass">
              {t("stripeAccount")}:
            </label>

            <div className="col-md-9 col-sm-12 col-12">
              {paymentDetails.stripeAccount ? (
                <>
                  <div>
                    <input
                      name="stripeAccount"
                      type={"text"}
                      className="form-control"
                      disabled={true}
                      value={paymentDetails.stripeAccount}
                    />

                    <button
                      type="button"
                      className="btn"
                      style={{ background: "#005099", color: "white" }}
                      disabled={!isEditModeEnabled || createStripe.isLoading}
                      onClick={() => createStripeHandler("update")}
                    >
                      {createStripe.isLoading
                        ? buttonLoading
                        : t("editDetails")}
                    </button>
                  </div>
                </>
              ) : (
                <button
                  type="button"
                  className="btn"
                  style={{ background: "#005099", color: "white" }}
                  disabled={!isEditModeEnabled || createStripe.isLoading}
                  onClick={() => createStripeHandler("create")}
                >
                  {createStripe.isLoading ? buttonLoading : t("linkStripe")}
                </button>
              )}
            </div>
          </div>
        )}
        {paymentDetails.paymentMethod == "6" && (
          <div className="mb-3 row tabBlockClass">
            <label className="col-sm-3 col-form-label labelWidthClass">
              {t("paypalAccount")}:
            </label>

            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="paypalAccount"
                type="text"
                className="form-control"
                disabled={!isEditModeEnabled}
                onChange={handleChange}
                value={paymentDetails.paypalAccount}
              />
            </div>
          </div>
        )}
        {paymentDetails.paymentMethod == "4" && (
          <>
            <div className="mb-3 row tabBlockClass">
              {/* <label className="col-sm-3 col-form-label labelWidthClass">
                {t("bankDetails")}:
              </label> */}
            <BankDetailsTabNew bank={bank} isEditModeEnabled={isEditModeEnabled} setIsEditModeEnabled={setIsEditModeEnabled} />
            </div>
          </>
        )}

        <div
          className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}
        >
          {paymentDetails.paymentMethod != "4" && <SubmitButton
            id={"1"}
            isSubmitting={!isEditModeEnabled}
            click={onSubmit}
            text="update"
            className="btn"
          />}
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsTab;
